<template>
  <div>
    <b-modal
      id="view-orderItem-details"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('View details')"
      hide-footer
      size="xl"
      @close="onCancel"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          :title="$t('MaterialVariants')"
          active
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("MaterialVariants") }}/{{ $t("Description") }}</strong>
          </template>
          <b-card-text><b-form>
                         <div
                           v-for="material in getMaterialVariantsOfOrder"
                           :key="material.materialVariantId"
                           :class="{
                             failed2: material.isStockAvailable == false,
                           }"
                           class="material-info"
                           style="
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 10px;
                            border: 1px solid lightgray;
                            box-shadow: none;
                            padding: 12px;
                            margin-top: 1%;
                            color: #212529;
                            border-radius: 6px;
                            width: 100%;
                            margin-right: 10px;
                            "
                         >
                           <div style="margin-top:10px;display: flex;justify-content: space-between;">
                             <p style="color: #344054; font-weight: 600;">
                               {{ $t('Name') }}:</p> <p>{{ material.name }}</p>

                           </div>
                           <div class="hr" />
                           <div style="margin-top:10px;display: flex;justify-content: space-between;">
                             <p style="color: #344054; font-weight: 600;">
                               {{ $t('MeasurementType') }}:
                             </p> <p>{{ material.measurementType }}</p>
                           </div>
                           <div class="hr" />
                           <div style="margin-top:10px;display: flex; justify-content: space-between;">
                             <p style="color: #344054; font-weight: 600;">
                               {{
                                 material.length == 0
                                   ? `${$t('Quantity')}:`
                                   : `${$t('Length')}:`
                               }}
                             </p>
                             <p>
                               {{
                                 material.length == 0
                                   ? (material.quantity === 1 ? material.quantity + ` ${$t('Part')}` : material.quantity > 0 ? material.quantity + ` ${$t('Parts')}` : "")
                                   : (material.length === 1 ? material.length + ` ${$t('Meter')}` : material.length > 0 ? material.length + ` ${$t('Meters')}` : "")
                               }}
                             </p>
                           </div>
                         </div>
                       </b-form>
            <div style="display:flex;gap:15px;">
              <b-form-group
                :label="$t('Width')"
              >
                <b-form-input
                  v-model="getOrderDescWidth.width"
                  disabled
                />
              </b-form-group>
              <b-form-group
                :label="$t('Height')"
              >
                <b-form-input
                  v-model="getOrderDescWidth.height"
                  disabled
                />
              </b-form-group>
              <b-form-group
                :label="$t('sewingType')"
              >
                <b-form-input
                  v-model="getOrderDescWidth.sewingType"
                  disabled
                />
              </b-form-group>
            </div>
            <b-form-group
              :label="$t('Descsription')"
              style="width:100%"
            >
              <b-form-textarea
                v-model="getOrderDescWidth.description"
                disabled
                style="width:100%"
              />
            </b-form-group>
          </b-card-text>
        </b-tab>
        <b-tab
          :title="$t('Files')"
          style="padding: 5px"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Files") }}</strong>
          </template>
          <div
            v-if="getimagesOrder.length < 1"
            style="text-align: center; margin: 20px"
          >
            No files found
          </div>

          <vuegalleryslideshow
            v-if="getimagesOrder.length > 0"
            :images="getImagesForGallery"
            :index="index"
            @update-active-index="setIndex"
          />
          <div
            v-for="(image, i) in getimagesOrder"
            :key="i"
          >
            <div
              v-if="image.fileType ==='Scetch'"
              class="files"
              style="display: flex;align-items: center; gap: 30px;"
            >
              <span style="color: #262E6C; font-weight: bold; width: 100px;">{{ $t("Sketchs") }}</span>
              <div>
                <img
                  v-b-tooltip.hover
                  :title="$t('ClickToViewSketchPhoto')"
                  class="image"
                  style="width: 100px;"
                  :src="image.fileUrl"
                  alt="Not an image"
                  @click="index = i"
                >
                <button
                  style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;  margin-left: 22px;"
                  @click="downloadFile(image.fileUrl)"
                >
                  <b-icon-download style="color: white;" />
                </button>
              </div>
            </div>
            <div
              v-if="image.fileType ==='Contract'"
              class="files"
              style="display: flex;align-items: center; gap: 30px; padding-top: 30px;"
            >
              <span style="color: #262E6C; font-weight: bold;width: 100px;">{{ $t("Contracts") }}</span>
              <div>
                <img
                  v-b-tooltip.hover
                  style="width: 100px;"
                  :title="$t('ClickToViewSketchPhoto')"
                  class="image"
                  alt="Not an image"
                  :src="image.fileUrl"
                  @click="index = i"
                >
                <button
                  style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px; margin-left: 22px;"
                  @click="downloadFile(image.fileUrl)"
                >
                  <b-icon-download style="color: white;" />
                </button>
              </div>
            </div>
            <div
              v-if="image.fileType ==='OtherDocument'"
              class="files"
              style="display: flex;align-items: center; gap: 30px; padding-top: 30px;"
            >
              <span style="color: #262E6C; font-weight: bold;">{{ $t("OtherDocuments") }}</span>
              <div>
                <img
                  v-b-tooltip.hover
                  style="width: 100px;"
                  :title="$t('ClickToViewSketchPhoto')"
                  class="image"
                  alt="Not an image"
                  :src="image.fileUrl"
                  @click="index = i"
                >
                <button
                  style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;  margin-left: 22px;"
                  @click="downloadFile(image.fileUrl)"
                >
                  <b-icon-download style="color: white;" />
                </button>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import vuegalleryslideshow from 'vue-gallery-slideshow';


export default {
  components: {
    vuegalleryslideshow,
  },
  // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
  props: ['order-item-id', 'active-order-status'],
  data() {
    return {
      tabIndex: 1,
      index: null,
    };
  },
  validations: {
  },
  computed: {
    ...mapGetters([
      'getProbeDates',
      'getimagesOrder',
      'getPrices',
      'getTwentyPrinciples',
      'getMaterialVariantsOfOrder',
      'getDescriptionOfOrder',
      'getOrderItemSize',
      'getLoggedInUser',
      'getOrderItemLocatioon',
      'getFirstNames',
      'getOrderDescWidth',
    ]),
    getImagesForGallery() {
      const images = this.getimagesOrder.map((image) => { return image.fileUrl });
      return images;
    },
  },
  watch: {
  },
  mounted() {
    console.log('Component mounted.', this.files);
  },
  methods: {
    ...mapActions([
      'getFileByOrderNumber',
    ]),
    openGallery(index) {
      this.index = index;
    },
    setIndex(index) {
      this.index = index;
    },
    downloadFile(fileUrl) {
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click()
      document.body.removeChild(link);
    },
    onCancel() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: flex-end;
}
.form2 {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  gap: 10px;
}
.hr{
  background-color: grey;
  min-width: 201px;
  height: 1px;
  border: 0.5px solid #A5A5A5;
  opacity: 0.34;
}
.files{
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  background-color: rgb(207, 207, 207);
  border: none;
  box-shadow: none;
  padding: 12px;
  color: #212529;
  border-radius: 8px;
  width: 35%;
  justify-content: space-between;
  align-items: center;
}
.image {
  cursor: pointer;
}
</style>
